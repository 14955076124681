import React from "react"
import styled from "styled-components"
import parse from "html-react-parser"
import { action_link } from "@assets/css/global"

const TextBlock = ({ addText }) => {
  return <Text>{parse(addText)}</Text>
}

const Text = styled.div`
  max-width: calc(100% - 3rem);
  @media (min-width: 48em) {
    max-width: 75%;
  }
  @media (min-width: 62em) {
    max-width: 50%;
  }

  margin: 0 auto;
  * {
    font-family: "Graphik", sans-serif;
    font-weight: 400;
  }

  a {
    ${action_link}
  }
`

export default TextBlock
