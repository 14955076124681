import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import { inner, image_aspect } from "@assets/css/global"
import { icg_blue } from "@assets/css/colours"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Projects = () => {
  const [filter, setFilter] = useState("")

  const { allWpProject } = useStaticQuery(graphql`
    query allWpProjects {
      allWpProject(
        filter: {
          categories: {
            nodes: { elemMatch: { slug: { regex: "/(then|now|future)/" } } }
          }
        }
      ) {
        edges {
          node {
            title
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 1600
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
            uri
            categories {
              nodes {
                name
                slug
              }
            }
            projectFields {
              logo {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 1600
                      placeholder: BLURRED
                      formats: [AUTO, WEBP]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const active_categories = allWpProject?.edges.map(
    (active_category, index) => {
      return {
        name: active_category?.node?.categories?.nodes[0]?.name,
        slug: active_category?.node?.categories?.nodes[0]?.slug,
      }
    }
  )

  /** Filtered list to remove duplicates in the case that a category is assigned to more than one project */
  const filtered_categories = active_categories?.filter(
    (ele, ind) =>
      ind === active_categories?.findIndex(elem => elem?.slug === ele?.slug)
  )

  const projects_list = allWpProject?.edges.map(project => {
    return {
      category: project?.node?.categories?.nodes[0]?.slug,
      image: project?.node?.featuredImage,
      url: project?.node?.uri,
      logo: project?.node?.projectFields?.logo,
      title: project?.node?.title,
    }
  })

  return (
    <ProjectsList>
      <Filters>
        <p>Sort Projects</p>
        <button
          className={filter === "" ? "active" : ""}
          onClick={() => setFilter("")}
        >
          All
        </button>
        <span> / </span>
        {filtered_categories != null &&
          filtered_categories?.map((filtered_category, index) => {
            return (
              <React.Fragment key={index}>
                <button
                  className={filter === filtered_category?.slug ? "active" : ""}
                  onClick={() => setFilter(filtered_category?.slug)}
                >
                  {filtered_category?.name}
                </button>
                {index + 1 !== filtered_categories?.length && <span> / </span>}
              </React.Fragment>
            )
          })}
      </Filters>
      <ActiveProjects>
        {projects_list
          .filter(
            project =>
              project?.category.includes(filter.toLowerCase()) || filter === ""
          )
          .map((active_project, index) => {
            return (
              <Link
                to={active_project?.url}
                key={index}
                aria-label={active_project?.title}
              >
                <GatsbyImage
                  alt={active_project?.image?.node?.altText}
                  image={getImage(active_project?.image?.node?.localFile)}
                />
                <Overlay>
                  {active_project?.logo != null && (
                    <div className="projects__project-logo">
                      {active_project?.logo?.mimeType === "image/svg+xml" && (
                        <img
                          src={active_project?.logo?.localFile?.publicURL}
                          alt={active_project?.title + " logo"}
                        />
                      )}
                      {active_project?.logo?.mimeType !== "image/svg+xml" && (
                        <GatsbyImage
                          image={getImage(active_project?.logo?.localFile)}
                          alt={active_project?.title + " logo"}
                        />
                      )}
                    </div>
                  )}
                </Overlay>
              </Link>
            )
          })}
      </ActiveProjects>
    </ProjectsList>
  )
}

const Overlay = styled.div`
  background: rgba(33, 42, 75, 0.75);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transition: 0.7s;

  &:hover {
    opacity: 1;
    transition: 0.7s;
  }

  .projects__project-logo {
    height: 35%;

    .gatsby-image-wrapper,
    img {
      height: 100%;
      width: auto;
      max-width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`

const ProjectsList = styled.div``

const Filters = styled.div`
  ${inner};

  * {
    color: ${icg_blue};
    font-size: 0.875rem;
  }

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1.5rem;

  p {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0;
    margin-right: 0.5rem;
    font-family: "degular-text", sans-serif;
  }

  button {
    position: relative;
    cursor: pointer;
    border: 0;
    background: transparent;
    padding: 0;
    font-family: "degular-text", sans-serif;
    margin: -0.0125rem 0.25rem 0 0.25rem;

    &.active {
      font-weight: 600;
    }

    &:after {
      font-weight: 400 !important;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -0.125rem;
      height: 0.0625rem;
      width: 0;
      background: ${icg_blue};
      transition: width 0.4s;
    }

    &:hover {
      &:before {
        width: 100%;
        transition: width 0.4s;
      }
    }
  }
`

const ActiveProjects = styled.div`
  a {
    position: relative;
    display: block;

    margin-bottom: 1.5rem;
    @media (min-width: 62em) {
      margin-bottom: 3rem;
    }

    > .gatsby-image-wrapper {
      width: 100%;
      ${image_aspect};
      height: 0;
    }
  }
`

export default Projects
