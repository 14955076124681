import React from "react"
import {
  Projects,
  HeaderText,
  TextBlock,
  ContactBlock,
  ImageBlock,
} from "@modules"
import { Fade } from "react-reveal"

const Module = ({ moduleData }) => {
  const moduleType = moduleData?.fieldGroupName

  const Default = () => <></>

  const modules = {
    page_Pagemodules_PageModules_HeaderText: HeaderText,
    page_Pagemodules_PageModules_ProjectsGrid: Projects,
    page_Pagemodules_PageModules_TextBlock: TextBlock,
    page_Pagemodules_PageModules_ContactBlock: ContactBlock,
    page_Pagemodules_PageModules_Image: ImageBlock,
    page_default: Default,
  }
  const ModuleTag = modules[moduleType]
    ? modules[moduleType]
    : modules["page_default"]

  return (
    <Fade>
      <section id={moduleData?.blockId ? moduleData?.blockId : null}>
        <ModuleTag {...moduleData}></ModuleTag>
      </section>
    </Fade>
  )
}

export default Module
