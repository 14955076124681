import React from "react"
import styled from "styled-components"
import { inner } from "@assets/css/global"
import parse from "html-react-parser"

const HeaderText = ({ contentLeft, contentRight }) => {
  return (
    <HeaderTextWrapper>
      <Left>{parse(contentLeft)}</Left>
      <Right>{parse(contentRight)}</Right>
    </HeaderTextWrapper>
  )
}

const HeaderTextWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  min-height: calc(100vh - 6rem);
  ${inner};
`
const Left = styled.div`
  width: 100%;
  @media (min-width: 75em) {
    width: 80%;
    padding-right: 6rem;
  }

  * {
    font-weight: 300 !important;
    font-size: 2.5em;
    @media (min-width: 48em) {
      font-size: 6vw;
    }
  }
`
const Right = styled.div`
  width: 100%;
  margin-top: 3rem;
  @media (min-width: 75em) {
    width: 20%;
    margin-top: 0;
    padding-top: 0.5rem;
  }

  * {
    font-weight: 400;
  }
`

export default HeaderText
