import React from "react"
import { graphql } from "gatsby"
import { Layout, Seo, Scroller } from "@components"
import Module from "@modules/module"
import styled from "styled-components"
import { featured_image, inner } from "@assets/css/global"
import { Fade } from "react-reveal"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Page = ({ data: { page } }) => {
  const featuredImage =
    page.featuredImage != null
      ? {
          localFile: page?.featuredImage?.node?.localFile,
          alt: page?.featuredImage?.node?.alt || ``,
        }
      : null

  return (
    <Layout>
      <Seo title={page?.title} description={page?.seo?.openGraphDescription} />
      <article className="page" itemScope itemType="http://schema.org/Article">
        {featuredImage && (
          <Fade>
            <section>
              <FeaturedImage>
                <GatsbyImage
                  image={getImage(featuredImage?.localFile)}
                  alt={featuredImage?.alt}
                />
              </FeaturedImage>
            </section>
          </Fade>
        )}

        {page?.pageMeta?.pageTitle && (
          <header>
            <PageTitle>{page.title}</PageTitle>
          </header>
        )}

        {page?.pageMeta?.pageScroller && (
          <Scroller showMobile={featuredImage != null ? true : false} />
        )}

        {page?.pageModules?.pageModules?.map((module, index) => {
          return <Module key={index} moduleData={module}></Module>
        })}
      </article>
    </Layout>
  )
}

export default Page

const PageTitle = styled.h1`
  font-family: "degular-display", sans-serif;
  font-weight: 400;
  margin-top: 3rem;
  margin-bottom: 3rem;
  ${inner};
  letter-spacing: 0.5px !important;
`
const FeaturedImage = styled.div`
  ${featured_image}
`

export const pageQuery = graphql`
  query PageById($id: String) {
    page: wpPage(id: { eq: $id }) {
      id
      title

      pageMeta {
        pageScroller
        pageTitle
      }

      seo {
        title
        opengraphDescription
        opengraphTitle
        opengraphType
        opengraphUrl
        readingTime
        twitterDescription
        twitterTitle
      }

      pageModules {
        pageModules {
          ... on WpPage_Pagemodules_PageModules_HeaderText {
            contentLeft
            contentRight
            fieldGroupName
            blockId
          }
          ... on WpPage_Pagemodules_PageModules_TextBlock {
            addText
            fieldGroupName
            blockId
          }
          ... on WpPage_Pagemodules_PageModules_ProjectsGrid {
            fieldGroupName
            blockId
          }
          ... on WpPage_Pagemodules_PageModules_ContactBlock {
            fieldGroupName
            blockId
          }
          ... on WpPage_Pagemodules_PageModules_Image {
            fieldGroupName
            blockId
            images {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1600
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1600
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`
