import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { image_aspect, module_spacing } from "@assets/css/global"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ImageBlock = ({ images }) => {
  var settings = {
    autoplay: true,
    autoplaySpeed: 4500,
    dots: false,
    arrows: false,
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <ImageWrapper>
      <Slider {...settings}>
        {images?.map((image, index) => {
          return (
            <GatsbyImage
              key={index}
              image={getImage(image?.localFile)}
              alt={image.altText}
            />
          )
        })}
      </Slider>
    </ImageWrapper>
  )
}

const ImageWrapper = styled.div`
  ${module_spacing};

  .gatsby-image-wrapper {
    position: relative;
    height: 0;
    ${image_aspect};
    img {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }
`

export default ImageBlock
