import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { inner, module_spacing } from "@assets/css/global"
import { ContactForm } from "@components"
import parse from "html-react-parser"

const ContactBlock = () => {
  const { wp } = useStaticQuery(graphql`
    query ContactQuery {
      wp {
        themeOptions {
          optionsFields {
            email {
              title
              url
              target
            }
            phone {
              url
              title
              target
            }
            address
          }
        }
      }
    }
  `)

  const contact_details = wp?.themeOptions?.optionsFields

  return (
    <ContactBlockWrapper>
      <ContactDetails>
        <h2>Get in touch</h2>
        {contact_details?.phone && (
          <a href={contact_details?.phone?.url}>
            {contact_details?.phone?.title}
          </a>
        )}
        {contact_details.email && (
          <a href={contact_details?.email?.url}>
            {contact_details?.email?.title}
          </a>
        )}
        <h2>Find us</h2>
        {contact_details?.address && (
          <address>{parse(contact_details?.address)}</address>
        )}
      </ContactDetails>
      <ContactForm></ContactForm>
    </ContactBlockWrapper>
  )
}

const ContactBlockWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  //201px = module_spacing + navbar height
  min-height: calc(100vh - 194px);

  form {
    width: 100%;

    @media (min-width: 62em) {
      width: calc(50% - 3rem);
    }
  }

  ${inner}
  ${module_spacing};
`
const ContactDetails = styled.div`
  width: 100%;
  margin-bottom: 5.75rem;

  @media (min-width: 62em) {
    width: calc(50% - 3rem);
    margin-bottom: 0;
  }

  > h2 {
    margin-top: 0;
    font-size: 1rem !important;
    font-weight: bold;
    letter-spacing: 0.0625rem;
    font-family: "degular-display", sans-serif !important;
    text-transform: uppercase;
    &:not(:first-of-type) {
      margin-top: 3rem;
    }
  }

  a {
    display: block;
    width: max-content;
    text-decoration: none;
    letter-spacing: 0.03125rem;
    line-height: 1.1;

    font-size: 1.5rem;
    @media (min-width: 48em) {
      font-size: 3rem;
    }

    font-family: "degular-display", sans-serif;
    max-width: 100%;
    font-weight: 300;
  }

  address {
    text-decoration: none;
    font-style: unset;

    h2 {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
`

export default ContactBlock
